
  import {Component, Vue} from 'vue-property-decorator';

  @Component
  export default class NotFound extends Vue {
    // method
    toTop(): void {
      this.$router.push({path: '/'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }
  }
